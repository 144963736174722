import "./header.scss";
import { initMobAccordions } from "../../js/accordionMob";

let scrollValue = 0;

function checkIsStickyHeader() {
    let header = document.querySelector(".layout__header");

    function check() {
        if (window.scrollY > 3) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }

    check();
    window.addEventListener("scroll", check);
}

function initMobileMenu() {
    let burger = document.querySelector(".m-header__burger");
    let mobileMenu = document.querySelector(".m-menu");
    let openClassName = "open";

    burger.addEventListener("click", (e) => {
        if (mobileMenu.classList.contains(openClassName)) {
            document.body.style.top = "";
            document.body.style.position = "";
            window.scrollTo(0, scrollValue);
            mobileMenu.classList.remove(openClassName);
        } else {
            scrollValue = window.scrollY;
            document.body.style.top = -scrollValue + "px";
            document.body.style.position = "fixed";
            mobileMenu.classList.add(openClassName);
        }
    });

    mobileMenu.addEventListener("click", (e) => {
        if (e.target !== e.currentTarget) return;

        mobileMenu.classList.remove(openClassName);
    });
}

// checkIsStickyHeader();
initMobileMenu();
initMobAccordions();

function initMobAccordions() {
    function initAccordionItem(accordion) {
        const title = accordion.querySelector(".mob-acc__title");
        const body = accordion.querySelector(".mob-acc__body");
        let isOpen = accordion.classList.contains("open");
        let height = isOpen ? title.clientHeight + body.clientHeight + 2 : title.clientHeight;

        function onTitleClick() {
            if (isOpen) {
                height = title.clientHeight;
                accordion.style.height = height + "px";
                accordion.classList.remove("visible");
            } else {
                height = title.clientHeight + body.clientHeight + 2;
                accordion.style.height = height + "px";
                accordion.classList.add("visible");
            }

            isOpen = !isOpen;
        }

        onTitleClick();
        onTitleClick();
        title.addEventListener("click", onTitleClick);
    }

    const accordionList = document.querySelectorAll(".mob-acc");

    accordionList.forEach(initAccordionItem);
}

export { initMobAccordions };

import "./index.page.scss";
import "../../html/header/header";
import "../../html/footer/footer";
import simpleParallax from "simple-parallax-js";
import { initSlider } from "../../js/slider";
import { initFadeIn } from "../../js/fadeIn";

new simpleParallax(document.getElementsByClassName("parallax"), {
    overflow: true,
});

initSlider();
initFadeIn();

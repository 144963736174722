function initSlider() {
    let slider = document.querySelector(".slider");
    let container = slider.querySelector(".slider__viewport-container");
    let slides = container.querySelectorAll(".slider__viewport-container-slide");
    let arrowPrev = slider.querySelector(".slider__arrow.prev");
    let arrowNext = slider.querySelector(".slider__arrow.next");
    let currentIndex = 0;
    let dots = [];

    function createDots() {
        let classNameBlock = "slider__dots";
        let classNameItem = `${classNameBlock}-item`;
        let dotsBlock = document.createElement("div");

        for (let i = 0; i < slides.length; i++) {
            let dotItem = document.createElement("div");

            dotItem.classList.add(classNameItem);

            dotsBlock.appendChild(dotItem);
            dotItem.addEventListener("click", () => selectNewSlideByIndex(i));
            dots.push(dotItem);
        }

        dotsBlock.classList.add(classNameBlock);
        slider.appendChild(dotsBlock);
    }

    function selectNewSlideByIndex(index) {
        if (index < 0) return;
        if (index >= slides.length) return;

        let { marginRight, width } = getComputedStyle(slides[0]);
        let newPosition = index * (parseFloat(width) + parseFloat(marginRight));

        container.style.transform = `translateX(-${newPosition}px)`;

        dots.forEach((dot, dotIndex) => {
            dot.classList.remove("active");

            if (index === dotIndex) {
                dot.classList.add("active");
            }
        });

        arrowPrev.classList.remove("disabled");
        arrowNext.classList.remove("disabled");

        if (index === 0) arrowPrev.classList.add("disabled");
        if (index === slides.length - 1) arrowNext.classList.add("disabled");

        currentIndex = index;
    }

    function onBtnPrevClick() {
        selectNewSlideByIndex(currentIndex - 1);
    }

    function onBtnNextClick() {
        selectNewSlideByIndex(currentIndex + 1);
    }

    createDots();
    selectNewSlideByIndex(currentIndex);

    arrowPrev.addEventListener("click", onBtnPrevClick);
    arrowNext.addEventListener("click", onBtnNextClick);
}

export { initSlider };

function initFadeIn() {
    let targets = document.querySelectorAll(".fade-in");

    let observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((item) => {
                if (item.isIntersecting) {
                    item.target.classList.add("visible");
                }
            });
        },
        {
            threshold: 0.5,
        },
    );

    targets.forEach((item) => {
        observer.observe(item);
    });
}

export { initFadeIn };
